import React, { useState, useEffect } from "react";

import { queryCache } from "react-query";
import {
  EuiFlexGroup,
  EuiFlexItem,
  EuiFlyout,
  EuiFlyoutBody,
  EuiFlyoutHeader,
  EuiFlyoutFooter,
  EuiButton,
  EuiText,
  EuiTitle,
  EuiForm,
  EuiFormRow,
  EuiFieldText,
  EuiFieldPassword,
  EuiFieldNumber,
} from "@elastic/eui";
import { useForm, Controller } from "react-hook-form";
import { baseApiUrl } from "../../envConfig";
import { useSelector, useDispatch } from "react-redux";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const AddUserFlatout = ({ setIsFlyoutVisible, restaurantId }) => {
  const editUser = useSelector((state) => state.user.editUser);
  
  const token = useSelector((state) => state.auth.token);
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  const { handleSubmit, errors, control, watch, reset, setValue } = useForm({
    defaultValues: {},
  });
  useEffect(() => {
    if (editUser) {
      const user = editUser.user;
      reset({
        name: user.name,
        email: user.email,
        mobile_number: user.mobile_number,
      });
    }
  }, [editUser, reset]);

  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [countryCode, setCountryCode] = useState();

  const onSubmit = async (data) => {
    setLoader(true);
    const mobileNumber = data.mobile_number;
    let url, method;
    if (editUser) {
      url = `${baseApiUrl}restaurants/${restaurantId}/users/${editUser.user.id}`;
      method = "PUT";
    } else {
      url = `${baseApiUrl}restaurants/${restaurantId}/users`;
        method = "POST";
    }
    const response = await fetch(url, {
      method,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
        },
        body: JSON.stringify({
          name: data.name,
          email: data.email,
          password: data.password,
          password_confirmation: data.confirm_password,
          mobile_number: data.mobile_number,
          country_code: countryCode,
        }),
      });
    const userJson = await response.json();
    setLoader(false);
    if (userJson.success) {
      setSuccessMessage(userJson.message);
      setSuccess(true);
      setTimeout(() => {
        setSuccess(false);
      }, 2000);
      reset();
      queryCache.invalidateQueries("getAllUser");
      setIsFlyoutVisible(false);
    } else {
      setErrorMessage(
        userJson.message ||
          userJson.errors?.email ||
          userJson.errors?.mobile_number
      );
      setError(true);
      setTimeout(() => {
        setError(false);
      }, 2000);
    }
    setLoader(false);
  };
  const handleCloseFlyout = () => {
    setIsFlyoutVisible(false);
    dispatch({
      type: "edit_user",
      payload: {
        editUser: null,
      },
    });
  };
  if (editUser && !watch("confirm_password") && !watch("password")) {
    delete errors["confirm_password"];
    delete errors["password"];
  }

  return (
    <EuiForm component="form">
      <EuiFlyout
        ownFocus
        onClose={handleCloseFlyout}
        aria-labelledby="flyoutTitle"
      >
        <EuiFlyoutHeader hasBorder>
          <EuiTitle size="m">
            <h2>{editUser ? "Edit User" : "Add User"}</h2>
          </EuiTitle>
        </EuiFlyoutHeader>
        <EuiFlyoutBody>
          {error && (
            <EuiText>
              <p style={{ color: "red", textAlign: "center" }}>
                {errorMessage}
              </p>
            </EuiText>
          )}
          {success && (
            <EuiText>
              <p style={{ color: "green", textAlign: "center" }}>
                {successMessage}
              </p>
            </EuiText>
          )}
          <EuiFlexGroup>
            <EuiFlexItem>
              <EuiFormRow
                label="Name"
                isInvalid={!!errors.name}
                error={errors.name ? errors.name.message : ""}
              >
                <Controller
                  as={EuiFieldText}
                  name="name"
                  control={control}
                  rules={{
                    required: "Please enter name",
                    minLength: {
                      value: 3,
                      message: "Minimum length should be 3",
                    },
                  }}
                  isInvalid={errors.name}
                  defaultValue=""
                />
              </EuiFormRow>
            </EuiFlexItem>
            <EuiFlexItem>
              <EuiFormRow
                label="Email"
                isInvalid={!!errors.email}
                error={errors.email ? errors.email.message : ""}
              >
                <Controller
                  as={EuiFieldText}
                  name="email"
                  control={control}
                  rules={{
                    required: "Please enter your email",
                    pattern: {
                      value:
                        /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                      message: "Please enter a valid email ",
                    },
                  }}
                  defaultValue=""
                />
              </EuiFormRow>
            </EuiFlexItem>
          </EuiFlexGroup>

          <EuiFlexGroup>
            <EuiFlexItem>
              <EuiFormRow
                label="Password"
                isInvalid={!!errors.password}
                error={errors.password ? errors.password.message : ""}
              >
                <Controller
                  as={EuiFieldPassword}
                  name="password"
                  control={control}
                  rules={
                    editUser === null || watch("password")
                      ? {
                          required: "Please enter a password",
                          minLength: {
                            value: 6,
                            message: "Minimum length should be 6",
                          },
                        }
                      : {}
                  }
                  isInvalid={errors.password}
                  defaultValue=""
                />
              </EuiFormRow>
            </EuiFlexItem>

            <EuiFlexItem>
              <EuiFormRow
                label="Confirm Password"
                isInvalid={!!errors.confirm_password}
                error={
                  errors.confirm_password ? errors.confirm_password.message : ""
                }
              >
                <Controller
                  as={EuiFieldPassword}
                  name="confirm_password"
                  control={control}
                  defaultValue=""
                  rules={
                    editUser === null || watch("confirm_password")
                      ? {
                          required: "Please confirm your password",
                          validate: (value) =>
                            value === watch("password") ||
                            "Passwords do not match",
                        }
                      : {}
                  }
                />
              </EuiFormRow>
            </EuiFlexItem>
          </EuiFlexGroup>

          <EuiFlexGroup>
            <EuiFlexItem>
              <EuiFormRow
                label="Mobile Number"
                isInvalid={!!errors.mobile_number}
                error={errors.mobile_number ? errors.mobile_number.message : ""}
              >
                <Controller
                  as={EuiFieldNumber}
                  name="mobile_number"
                  control={control}
                  rules={{
                    required: "Please enter your mobile number",
                  }}
                  defaultValue=""
                  render={({ field }) => (
                    <PhoneInput
                      {...field}
                      country={"in"} // Default to India, change as needed
                      enableSearch={true}
                      disableSearchIcon={true}
                      countryCodeEditable={false} 
                      inputClass="mobileNumberContainerClass"
                      onChange={(value, data) => {
                        console.log("PhoneInput data:", data?.dialCode);
                        const mobileNumber = value.slice(-10); // Get the last 10 digits for mobile number
                        setValue("mobile_number", mobileNumber); 
                        if (data && data.dialCode) {
                          setCountryCode(`+${data?.dialCode}`); 
                        }
                      }}
                    />
                  )}
                />
              </EuiFormRow>
            </EuiFlexItem>
            <EuiFlexItem></EuiFlexItem>
          </EuiFlexGroup>
        </EuiFlyoutBody>
        <EuiFlyoutFooter>
          <EuiFlexGroup justifyContent="flexEnd">
            <EuiButton
              isLoading={loader}
              onClick={handleSubmit(onSubmit)}
              type="submit"
              fill
              style={{ margin: "10px 0px" }}
            >
              Save form
            </EuiButton>
          </EuiFlexGroup>
        </EuiFlyoutFooter>
      </EuiFlyout>
    </EuiForm>
  );
};

export default AddUserFlatout;
